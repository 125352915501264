/**
 * Persistent JS container for all custom JS across this site
 */
window.EII = window.EII || {};

/**
 * Validation class for Etna Contact forms that updates valid/invalid classes on-the-fly.
 */
class EiiValidate {
	/**
	 * Validate a form and its fields based on basic validation tests.
	 *
	 * @param  {string} formsSelector - Custom selector for form(s) to run validation in, or use Etna Contact defaults.
	 * @param  {array} fieldsSelector - Custom selector for field(s) to run validation on, or use Etna Contact defaults TBD: add string detection.
	 */
	constructor(formsSelector, fieldsSelector) {
		// sanity - ensure 'this' is class, rather than window
		if ( ! (this instanceof EiiValidate)) {
			return new EiiValidate();
		}

		// wire arguments or defaults - note that for defaults we're targeting "required" fields only

		/**
		 * @property {string} defaultFormsSelector - Default selector for forms
		 */
		this.defaultFormsSelector = '.form-etna-contact';

		/**
		 * @property {string} defaultFieldsSelector - Default CSS selector for form fields
		 */
		this.defaultFieldsSelector = this.defaultFormsSelector + ' input[aria-required="true"],'
									+ this.defaultFormsSelector + ' select[aria-required="true"],'
									+ this.defaultFormsSelector + ' textarea[aria-required="true"]';

		/**
		 * @property {NodeList} forms - Nodelist of form elements.
		 */
		this.forms  = (formsSelector) ? document.querySelectorAll(formsSelector)  : document.querySelectorAll(this.defaultFormsSelector);

		/**
		 * @property {NodeList} fields - Nodelist of field elements.
		 */
		this.fields = (fieldsSelector) ? document.querySelectorAll(fieldsSelector) : document.querySelectorAll(this.defaultFieldsSelector);

		/**
		 * @property {NodeList} submitButtons - Nodelist of form submit buttons
		 */
		this.submitButtons = (formsSelector) ? document.querySelectorAll(formsSelector + ' [type="submit"]') : document.querySelectorAll(this.defaultFormsSelector + ' [type="submit"]');

		/**
		 * @property {array} defaultFormsEvents - Default events to listen to for forms
		 */
		this.defaultFormsEvents = ['submit'];

		 /**
		  * @property {array} defaultFieldEvents - Default events to listen to for form fields
		  */
		this.defaultFieldEvents = ['blur', 'change', 'input'];

		// spring forth
		return this.init();
	}

	/**
	 * Initialize the class by wiring event listeners
	 */
	init() {
		// apply events listeners for forms
		// TBD: see below
		//this.formsEvents();

		// apply events listeners for fields
		this.fieldsEvents();

		// apply event listeners for submitButtons
		//this.submitButtonEvents();
		/*
		window.addEventListener('load', () => {
			// use delay so other JS that adds disable att is ready
			setTimeout(() => {
				this.submitButtonEvents();
			}, 500);
		});
		*/
	}

	/**
	 * Apply event listeners for forms
	 */
	formsEvents() {
		this.forms.forEach((form) => {
			let events = this.defaultFormEvents;

			events.forEach((event) => {
				form.addEventListener(event, newevent => {
					// TBD: validate all fields on before submit
				})
			});
		});
	}

	/**
	 * Validate all target fields in form when submit clicked
	 */
	submitButtonEvents() {
		/*
		let events = ['click', 'focus'];

		this.submitButtons.forEach((submit) => {
			submit.removeAttribute('disabled');

			events.forEach((event) => {
				submit.addEventListener(event, newevent => {
					let form = submit.closest('form');

					if (form) {
						let subFieldsSelector = this.defaultFieldsSelector.replaceAll(this.defaultFormsSelector, '');
						let subFields = form.querySelectorAll(subFieldsSelector);

						subFields.forEach((subField) => {
							const blurEvent = new Event('blur', { bubbles: true, cancelable: true });
							subField.dispatchEvent(blurEvent);
						});
					}
				});
			});
		});
		*/
	}

	/**
	 * Apply event listeners for fields
	 */
	fieldsEvents() {
		this.fields.forEach((field) => {
			let events = this.defaultFieldEvents;

			events.forEach((event) => {
				field.addEventListener(event, newevent => {
					this.setValidationClass(field);
				})
			});
		});
	}

	/**
	 * Apply validation class to a field based on validation result.
	 *
	 * @param  {Node.ELEMENT_NODE} field - The form field - must be an element node.
	 */
	setValidationClass(field) {
		if ( this.validateField(field) === 'success' ) {
			field.parentElement.parentElement.classList.remove('invalid');
			field.parentElement.parentElement.classList.add('valid');
		} else {
			field.parentElement.parentElement.classList.add('invalid');
			field.parentElement.parentElement.classList.remove('valid');
		}
	}

	/**
	 * Validate a form field based on its type / properties
	 *
	 * @param  {Node.ELEMENT_NODE} field - The form field to be validated - must be an element node.
	 * @returns {string} result - validation success/fail, using strings rather than booleans to reduce potential truthiness issues.
	 */
	validateField(field) {
		var result = 'fail';

		switch(true) {
			case field.type === 'email':
				// check for valid email addresses
				result = ( /\S+@\S+\.\S+/.test(field.value) ) ? 'success' : 'fail';
				break;

			case field.name === 'zip':
				result = ( this.postalFilter(field.value) !== null ) ? 'success' : 'fail';
				break;

			case field.type === 'tel':
				// check for valid phone number - hattip: https://stackoverflow.com/a/16702965
				result = ( /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(field.value) ) ? 'success' : 'fail';
				break;

			case field.nodeName === 'SELECT':
				// check that the select's value is non-empty
				result = ( field.value !== '' ) ? 'success' : 'fail';
				break;

			default:
				// check that the input's value is a non-empty string
				result = ( field.value.trim() !== "" ) ? 'success' : 'fail';
				// for radios and checkboxes, check for non-checked
				result = ( ['radio', 'checkbox'].includes(field.type) ) ? ( field?.checked ? 'success' : 'fail' ) : result;
				break;
		}

		return result;
	}

	/**
	 * TBD: Validate a form before it submits
	 *
	 * @param  {Node.ELEMENT_NODE} form - The form to be validated - must be an element node.
	 */
	validateForm(form) {
		// TBD: catch submit, preventDefault, validate fields, if none fail, allow submit

	}

	/**
	 * Validate a zip code
	 * hattip - https://stackoverflow.com/a/26788801
	 *
	 * @param {integer} postalCode
	 * @returns null || valid US/CA postalCode
	 */
	postalFilter(postalCode) {

		if (! postalCode) {
			return null;
		}

		postalCode = postalCode.toString().trim();

		var us = new RegExp("^\\d{5}(-{0,1}\\d{4})?$");
		var ca = new RegExp(/([ABCEGHJKLMNPRSTVXY]\d)([ABCEGHJKLMNPRSTVWXYZ]\d){2}/i);

		if (us.test(postalCode.toString())) {
			return postalCode;
		}

		if (ca.test(postalCode.toString().replace(/\W+/g, ''))) {
			return postalCode;
		}
		return null;
	}
};

// fire class loader on domready
// -- this could be separated out into main.js to only load when called

// wrap onReady in an anonymous func to prevent collision with other onReady functions in the global scope
(function(){
	// run onReady function if dom is ready or apply event listener to do so when it is
	if (document.readyState !== "loading") {
		onReady(); // Or setTimeout(onReady, 0); if you want it consistently async
	} else {
		document.addEventListener("DOMContentLoaded", onReady);
	}

	/**
	 * Function used to init Validation class
	 */
	function onReady() {
		// default init
		window.EII.Validate = new EiiValidate();

		// custom init example with specific form and field selectors
		// window.EII.Validate = new EiiValidate('.form-etna-contact', ['input[type="email"]','input[type="phone"]']);
	}
})();
