function handleFiles(cert_id = '', field_name = '', min_files = 0, max_files = 10, max_size = 26214400) {
	var files_valid = true;
	let form = document.querySelector('#anchor_' + cert_id + '_form');

	if ( ! form) {
		return false;
	}

	let fileList_Contactform = form.querySelector('.fileList');
	let fileInputs = (field_name != '') ? form.querySelectorAll('[name="' + field_name + '"]') : form.querySelectorAll('[type="file"]');
	let files = [];

	fileInputs.forEach(tf => files = files.concat([...tf.files]));

	if (files.length == 0) {
		if(fileList_Contactform) {
			fileList_Contactform.innerHTML = "<p><mark><strong>No files selected!</strong></mark></p>";
		}
		files_valid = false;
	} else if (files.length > max_files) {
		if(fileList_Contactform) {
			fileList_Contactform.innerHTML = "<p><mark><strong>Please select no more than " + max_files + " files</strong></mark></p>";
		}
		files_valid = false;
	} else if (files.length < min_files ) {
		if(fileList_Contactform) {
			fileList_Contactform.innerHTML = "<p><mark><strong>Please select at least " + min_files + " files</strong></mark></p>";
		}
		files_valid = false;
	}  else {
		if(fileList_Contactform) {
			fileList_Contactform.innerHTML = "";
			var list = document.createElement("ul");
			fileList_Contactform.appendChild(list);

			for (var i = 0; i < files.length; i++) {
				var li = document.createElement("li");
				list.appendChild(li);

				var img = document.createElement("img");
				img.src = window.URL.createObjectURL(files[i]);
				img.height = 150;
				img.width = 150;
				img.onload = function() {
					window.URL.revokeObjectURL(this.src);
				}
				li.appendChild(img);

				var max_upload_size = max_size;
				var info = document.createElement("span");
				if (files[i].size < max_upload_size ) {
					info.innerHTML = "<strong>File name:</strong> " + files[i].name + "<br /><strong>File type:</strong> " + files[i].type + "<br /><strong>File size:</strong> " + (files[i].size * 0.00000095367432).toFixed(3) +  " MB "  ;
				} else {
					info.innerHTML = "<strong>File name:</strong> " + files[i].name + "<br /><strong>File size:</strong> " + (files[i].size * 0.00000095367432).toFixed(3) +  " MB " +  "<br /><mark>File too large. Please choose files smaller than "+ (max_upload_size * 0.00000095367432).toFixed(3) + " MB. </mark>"  ;
					files_valid = false;
				}

				li.appendChild(info);
			}
		}
	}

	if (files_valid == false) {
		form.querySelector('.submit').setAttribute('disabled', '');
	} else {
		form.querySelector('.submit').removeAttribute('disabled');
	}
}

window.handleFiles = handleFiles;
