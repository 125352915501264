/**
 * IIFE for onready/window load logic
 */
(function() {
	// run onReady function if dom is ready or apply event listener to do so when it is
	if (document.readyState !== "loading") {
		onReady(); // Or setTimeout(onReady, 0); if you want it consistently async
	} else {
		document.addEventListener("DOMContentLoaded", onReady);
	}
})();

/**
 * Loop all tel inputs on the page when DOM ready
 */
function onReady() {
	var tel_inputs = document.querySelectorAll('.form-etna-contact [type="tel"]');

	tel_inputs.forEach((el, i) => {
		main(el);
	});
}

/**
 * main
 * process to run on the tel input elements
 *
 * @param {*} el - the tel input element
 */
function main(el) {
	var mask = el.getAttribute('data-mask'),
		isReq = el.getAttribute('aria-required'),
		first = mask.indexOf('_'),
		fieldsL = mask.replace(/[^_]/gm, '').length,
		clean = mask.replace(/[^0-9_]/gm, ''),
		indexes = [];

	for (var i = 0; i < clean.length; i++) {
		if ( ! isNaN(clean[i])) {
			indexes.push(i);
		}
	}

	el.value = mask;
	el.clean = mask.replace(/[^0-9]/gm, '');

	if ( !! isReq) {
		isReq = true;
	}

	el.addEventListener('focus', function(event) {
		event.preventDefault();
	});

	el.addEventListener('click', function(event) {
		event.preventDefault();
		var start = el.value.indexOf('_');

		if (start == -1) {
			start = el.value.length;
		}

		el.setSelectionRange(start, start);
	});

	el.addEventListener('paste', function(event) {
		var start = el.selectionStart;

		if (start < first) {
			el.value = '_' + el.value;
		}
	});

	el.addEventListener('input', function(event) {
		var start = el.selectionStart;
		maskIt(el, event, start, first, indexes, mask, isReq);
	});
}

/**
 * maskIt
 * masking logic for input
 *
 * @param  {Event} event - the change event
 * @param  {number} start - the start index for the number
 */
function maskIt(el, event, start, first, indexes, mask, isReq) {
	var value = el.value,
		cursor = 0,
		filtered = value.replace(/[^0-9]/gm, ''),
		result = '';

	if (value.length < first) {
		value = mask + value;
		filtered = value.replace(/[^0-9]/gm, '');
	}

	for (var i = 0; i < filtered.length; i++) {
		if (indexes.indexOf(i) == -1) {
			result += filtered[i];
		}
	}

	value = '';

	for (var i = 0; i < mask.length; i++) {
		if (mask[i] == '_' && result) {
			value += result[0];
			result = result.slice(1);
			cursor = i + 1;
		} else {
			value += mask[i];
		}
	}

	if (cursor < first) {
		cursor = first;
	}

	el.value = value;
	el.clean = el.value.replace(/[^0-9]/gm, '');
	el.setSelectionRange(cursor,cursor);
}
