/**
 * IIFE for onready/window load logic
 */
(function() {
	// run onReady function if dom is ready or apply event listener to do so when it is
	if (document.readyState !== "loading") {
		onReady(); // Or setTimeout(onReady, 0); if you want it consistently async
	} else {
		document.addEventListener("DOMContentLoaded", onReady);
	}
})();

/**
 * Loop all tel inputs on the page when DOM ready
 */
function onReady() {
	let successForms = document.querySelectorAll('.form-success');

	successForms.forEach((el, i) => {
		let formId = el.getAttribute('id');
		let dupeForms = document.querySelectorAll('#' + formId);
		let successMsg = el.querySelector('.responseValid');
		let successMsgClean = '';

		if ( successMsg ) {
			successMsgClean = successMsg.cloneNode(true);
			successMsgClean.innerHTML = successMsgClean.innerHTML.replace(/<script.*?>.*?<\/script>/gis, '');
		}

		if (dupeForms.length > 1) {
			dupeForms.forEach(form => {
				let failMsg = form.querySelector('.responseInvalid');

				if (failMsg) {
					failMsg.parentNode.append(successMsgClean);
					failMsg.remove();
				}

				form.classList.add('form-success');
			});
		}
	});
}
